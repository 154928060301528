import { functions } from '../../firestore';

export default class {
  constructor() {
    this.repoVulnerabilityCall = 'shepherd/getRepoVulnerability';
  }

  getRepoVulnerability() {
    return new Promise((resolve) => {
      const getImages = functions.httpsCallable(this.repoVulnerabilityCall);
      getImages().then((result) => {
        resolve(result.data);
      });
    });
  }
}
