<template>
  <v-card>
    <div class="d-flex justify-space-between ma-3 mt-3">
      <div>
        <div v-if="gh_token" style="margin-bottom: 10px">
          There is a saved github token
        </div>
        <a ref="githubTokenLink" v-else :href='`https://github.com/login/oauth/authorize?client_id=${clientID && clientID.clientID}&scope=user%20repo`' style="margin: 10px" @click="removeToken">
          <img src="@/assets/icons/github.png" alt=""/>
        </a>
      </div>

      <div class="d-flex">
        <div v-if="currentUser && currentUser.merge_permission">
          <img src="@/assets/icons/superuser.svg" alt=""/>You have merge permissions
          <v-btn :to="'/code/shame'" small></v-btn>
        </div>
        <v-btn v-else @click="requestMergePermission" :loading="loading" small>
          Request Merge Permission
        </v-btn>
      </div>
    </div>
    <v-card>
      <div class="d-inline-flex justify-space-between" style="height: 10px; width: 100%">
        <div class="d-inline-flex">
          <v-autocomplete
            v-model="selectedSprint"
            :items="sprintNames"
            item-text="name"
            label="Sprint names"
            outlined
            chips
            small-chips
            dense
            filled
            multiple
            clearable
            class="mr-5 ml-5"
          />
          <v-autocomplete
            v-model="selectedDev"
            :items="developerNames"
            item-text="name"
            label="Developer names"
            outlined
            chips
            small-chips
            dense
            filled
            multiple
            clearable
            class="mr-5"
          />
          <v-autocomplete
            v-model="prCurrentStatus"
            :items="prStatusOption"
            item-text="name"
            label="Status"
            outlined
            chips
            small-chips
            dense
            filled
            multiple
            clearable
            class="mr-5"
          />
        </div>
        <div class="d-inline-flex">
          <v-btn class="mr-5" v-if="issues && issues.length" @click="selectedPrIssues"> PR issues: {{ issues && issues.length }}</v-btn>
          <v-btn class="mr-5" v-if="requestedReviews && requestedReviews.length" @click="selectedPrReviews"> Requested reviews: {{ requestedReviews && requestedReviews.length }}</v-btn>
        </div>
      </div>
      <v-data-table
        group-by="sprintName"
        :headers="headers"
        :items="itemsTable"
        :search="search"
        :loading="!mondayItems"
        :items-per-page="30"
        multi-sort
        dense
        show-group-by
        class="pa-5 mb-5"
        @click:row="setItem
      ">
        <template v-slot:top>
          <v-text-field v-model="search" label="search"></v-text-field>
        </template>

        <template v-slot:item.status="{ item }">
          <div :style="{'background-color':colorMap[item.status]}" class="status-indicator"> {{item.status}}</div>
        </template>

        <template v-slot:item.prStatus="{ item }">
          <v-list dense>
            <v-list-item v-for="(status, i) in item.prStatus" :key="i" dense>
              <div class="d-inline-flex">
                <img v-if="!!status.merged_at" src="@/assets/icons/merged.svg" alt="" class="ml-1" />
                <img v-else-if="status.state == 'open'" src="@/assets/icons/pr.svg" alt="" class="ml-1" />
                <img v-else src="@/assets/icons/closed.svg" alt="" />
                <img v-if="status.state == 'open' && !status.mergeable" src="@/assets/icons/issues.svg" alt="" class="ml-1" />
                <a :href="status.url" class="ml-1" target="_blank">{{ status.repoName }}</a>
                <img v-if="status.approvedStatus == 'already approved' || status.approvedStatus == 'APPROVED'" src="@/assets/icons/check.svg" alt="" class="ml-1" />
              </div>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
  import { db, auth } from '@/firestore';
  import { mapGetters } from 'vuex'
  import code from "@/model/code/Code"
  // const Github = require('@/API/Github/Api.js');
  // const Monday = require('@/API/Monday/Api.js');

  export default {
    name: 'CodeList',
    data() {
      return {
        dialog: false,
        dialogPR: false,
        reposItems: [],
        selectedItem: undefined,
        prCurrentStatus: ['merged', 'open', 'issues', 'nopr', 'closed'],
        prStatusOption: ['merged', 'open', 'issues', 'nopr', 'closed'],
        selectedDev: undefined,
        developerNames: [],
        colorMap:{
          'Backlog' : 'rgb(196, 196, 196)',
          'Blocked': 'rgb(187, 51, 84)',
          'Cancelled' : 'rgb(153, 117, 108)',
          'In Progress': 'rgb(253, 171, 61)',
          'Local Done': 'rgb(255, 203, 0)',
          'Waiting PR Approval': 'rgb(255, 100, 46)',
          'Beta In Review': 'rgb(126, 59, 138)',
          'Beta Tested Ok': 'rgb(161, 227, 246)',
          'Beta Test Failed': 'rgb(255, 173, 173)',
          'Launched to Production': 'rgb(3, 127, 76)',
          'Staging Test Failed': 'rgb(255, 117, 117)',
          'Staging Tested Ok': 'rgb(102, 204, 255)',
          'Staging In Review': 'rgb(64, 22, 148)',
          'Release In Review': 'rgb(162, 93, 220)',
          'Release Tested Ok': 'rgb(104, 161, 189)',
          'Release Test Failed': 'rgb(255, 90, 196)',
          'Alpha In Review': 'rgb(120, 75, 209)',
          'Alpha Tested Ok': 'rgb(169, 190, 232)',
          'Alpha Test Failed': 'rgb(250, 161, 241)',
          'In Review': 'rgb(126, 59, 138)',
        },
        headers: [
          { text: 'Sprint', value: 'sprintName', groupable: true, },
          { text: 'Status', value: 'status' },
          { text: 'Name', value: 'name', groupable: false, },
          { text: 'Dev', value: 'people' },
          { text: 'Tags', value: 'tags' },
          { text: 'Branch name on Item', value: 'branch', groupable: false, },
          { text: 'Id', value: 'id', groupable: false, },
        ],
        search: undefined,
        sprintNames: [],
        selectedSprint: undefined,
        showReview: false,
        userMap: undefined,
        clientID: undefined,
        currentUser: {},
        loading: false,
        authCurrenUserDisplayName: undefined,
      };
    },
    async created() {
      await this.$store.dispatch('initialise')
      // it normalizes the user name due to issue with Github, Monday, Google sending diff characters and people registering wrong names
      this.authCurrenUserDisplayName = auth.currentUser.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      // setup the gh_token if not already present
      await this.$store.dispatch('gh_setup')

      // retrieving and ordering Monday items, selecting the current Sprint
      const res = await db.collection('code').doc('monday').collection('sprintNames').get();
      this.sprintNames = res.docs.map(item=>item.data()).sort((a, b) => this.order(b.name) - this.order(a.name));

      const savedSprintFilter = localStorage.getItem('selectedSprint')
      const savedPrStatusFilter = localStorage.getItem('prCurrentStatus')
      const savedDevFilter = localStorage.getItem('selectedDev')

      if(savedSprintFilter && savedSprintFilter !='undefined' ){
        this.selectedSprint = savedSprintFilter.split(',')
      }else{
        this.selectedSprint = [this.sprintNames[3].name, this.sprintNames[4].name, this.sprintNames[5].name];
      }

      if(savedPrStatusFilter && savedPrStatusFilter != 'undefined'){
        this.prCurrentStatus = savedPrStatusFilter.split(',')
      }

      // retrieving user
      const res1 = await db.collection('code').doc('github').collection('developerNames').get();
      this.developerNames = res1.docs.map(item=>item.data())

      this.currentUser = this.developerNames.find(dev=>dev.name.toLowerCase() === this.authCurrenUserDisplayName.toLowerCase())

      if(savedDevFilter && savedDevFilter != 'undefined'){
        this.selectedDev = savedDevFilter.split(',')
      }else{
        if (this.developerNames.map(item => item.name).includes(this.authCurrenUserDisplayName)) {
          this.selectedDev = [this.authCurrenUserDisplayName];
        }
      }


      this.userMap = this.developerNames.reduce((obj, item) => Object.assign(obj, { [item.github]: item.name }), {})
    },
    methods: {
      order(name){
        const regex = new RegExp('Kanban|No longer required|Jira Previous|Unscheduled|Complete Bugs|Complete Non-Sprint Items', 'g');
        const regex2 = new RegExp('Housekeeping In Review|Non Sprint Items', 'g');
        const regex3 = new RegExp('Bugs|Future Sprint|Complete Housekeeping|Project Sirling', 'g');
        if(regex.test(name)){return -10}
        if(regex2.test(name)){return 10000000000000}
        if(regex3.test(name)){return 1}
        const sanitised = name.replace("Sprint ", "")
        const order2 = sanitised.includes("-") ? sanitised.replace(/\d+/g, n => +n+10000).split("-").join("") : parseInt(sanitised)
        return order2
      },
      removeToken(){
        localStorage.removeItem('gh_token')
      },
      reset(){
        this.selectedSprint = [this.sprintNames[2].name, this.sprintNames[3].name, this.sprintNames[4].name]
        this.showReview = false
        this.prCurrentStatus = ['merged', 'open', 'issues', 'nopr', 'closed']
        if (this.developerNames.map(item => item.name).includes(this.authCurrenUserDisplayName)) {
          this.selectedDev = [this.authCurrenUserDisplayName];
        }
      },
      async requestMergePermission(){
        try {
          this.loading = true
          const result = await code.mergeTeam()
          this.loading = false
          this.$notify({
            type: result === 'Permission Granted' ? 'success' : 'warning',
            title: "Permission",
            title: result,
          });
          const myTimeout = setTimeout(()=>{window.location.reload();}, 3000);
        } catch (err) {
          console.error(err)
          this.loading = false
          this.$notify({
            type: "error",
            text: `There has been an error: ${err}`,
          });
        }
      },
      selectedPrIssues(){
        if(this.prCurrentStatus[0] != ["issues"]){
          if (this.developerNames.map(item => item.name).includes(this.authCurrenUserDisplayName)) {
            this.selectedDev = [this.authCurrenUserDisplayName];
          }
          this.prCurrentStatus = ["issues"]
          this.selectedSprint = []
        }else{
          this.reset()
        }
      },
      selectedPrReviews(){
        if(!this.showReview){
          this.selectedDev = []
          this.selectedSprint = []
          this.showReview = true
        }else{
          this.reset()
        }
        return
      },
      setItem(row) {
        this.$router.push({name: "code item", params: {itemId: row.id}});
      },
      setFilters() {
        localStorage.setItem('prCurrentStatus', this.prCurrentStatus)
        localStorage.setItem('selectedDev', this.selectedDev)
        localStorage.setItem('selectedSprint', this.selectedSprint)
      },
    },
    watch: {
      issues(newValue) {
        const count = newValue && newValue.length
        if (count) {
          var favicon = document.querySelector("link[rel~='icon']");
          if (!favicon) {
              favicon = document.createElement('link');
              favicon.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(favicon);
          }
          const faviconSize = 32;
          const canvas = document.createElement('canvas');
          canvas.width = faviconSize;
          canvas.height = faviconSize;
          const context = canvas.getContext('2d');
          const img = document.createElement('img');
          img.src = favicon.href;
          img.onload = () => {
              context.drawImage(img, 0, 0, faviconSize, faviconSize);
              if (count > 0) {
                // Draw Notification Circle
                context.beginPath();
                context.arc(canvas.width - faviconSize / 3, faviconSize / 3, faviconSize / 3, 0, 2 * Math.PI);
                context.fillStyle = '#FF0000';
                context.fill();

                // Draw Notification Number
                context.font = '22px "helvetica", sans-serif';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillStyle = '#FFFFFF';
                context.fillText(String(count), canvas.width - faviconSize / 3, faviconSize / 3);
                // Replace favicon
                favicon.href = canvas.toDataURL('image/png');
              } else {
                (favicon).href = favurl;
              }
          }
        }
      },
      prCurrentStatus(){
        this.setFilters()
      },
      selectedDev(){
        this.setFilters()
      },
      selectedSprint(){
        this.setFilters()
      }
    },
    computed: {
      ...mapGetters({"mondayItems":"getMondayItems", "gh_token":"getToken"}),
      itemsTable() {
        if (
          (this.selectedSprint && this.selectedSprint.length) ||
          (this.selectedDev && this.selectedDev.length) ||
          (this.prCurrentStatus && this.prCurrentStatus.length)
        ) {
          const selectedDev =
            this.selectedDev && this.selectedDev.length > 0 ? this.selectedDev : this.developerNames.map(item => item.name);

          const selectedSprint =
            this.selectedSprint && this.selectedSprint.length > 0 ? this.selectedSprint : this.sprintNames.map(item => item.name);

          return this.mondayItems.filter(item => {
            let reviewNeeded = !this.showReview
            if(this.showReview){
              if(this.requestedReviews.map(item=>item.id).includes(item.id) && !reviewNeeded){
                reviewNeeded = true
              }
            }
            let statusItem = 
              (this.prCurrentStatus.includes('merged') && item.prStatus && item.prStatus.some(subitem => !!subitem.merged_at)) ||
              (this.prCurrentStatus.includes('open') && item.prStatus && item.prStatus.some(subitem => subitem.state === 'open')) ||
              (this.prCurrentStatus.includes('issues') && item.prStatus && item.prStatus.some(status => !status.mergeable && !status.merged_at && status.state !== "closed")) ||
              (this.prCurrentStatus.includes('closed') && item.prStatus && item.prStatus.some(subitem => subitem.state === 'closed')) ||
              (this.prCurrentStatus.includes('nopr') && !(item.prStatus && item.prStatus.length)) ||
              this.prCurrentStatus.length === 0;
            
            return (
              reviewNeeded &&
              statusItem &&
              item.people && item.people.split(',').some(subdev => selectedSprint.includes(item.sprintName) && selectedDev.includes(subdev.trim()))
            );
          }).sort((a, b) => this.order(a.sprintName) - this.order(b.sprintName));
        } else {
          return this.mondayItems;
        }
      },
      issues() {
        return this.mondayItems && this.mondayItems.filter(item => {
          if (item && item.prStatus && item.people.includes(this.authCurrenUserDisplayName)) {
            return item.prStatus.some(status => !status.mergeable && !status.merged_at && status.state !== "closed");
          } else {
            return false;
          }
        });
      },
      requestedReviews(){
        return this.mondayItems && this.mondayItems.filter(item => {
          if (item && item.prStatus && item.prStatus.length) {
            for(const repoStatus of item.prStatus){
              const condition = repoStatus.requested_reviewers &&
              repoStatus.requested_reviewers.map(user=>this.userMap && this.userMap[user]).includes(this.authCurrenUserDisplayName) &&
              !repoStatus.merged_at &&
              !["APPROVED AND MERGED", "APPROVED"].includes(repoStatus.approvedStatus) &&
              repoStatus.state!== 'closed' &&
              (!item.people.split(",").includes(this.authCurrenUserDisplayName))

              if(condition){
                return true
              }
            }
          } else {
            return false;
          }
        });
      }
    },
    destroyed(){
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = '/favicon.ico';
    },
    firestore() {
      return {
        clientID: db
          .collection('code')
          .doc('github')
      };
    }
  };
</script>

<style scoped>
.status-indicator{
  flex-basis: 129px;
  max-width: 129px;
  border-radius: 5px;
  margin: 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 13px;
  position: relative;
  padding: 5px;
  color: #eeffff;
}
</style>
