<template>
  <div>
    <v-card :loading="tableData === null">
      <v-card-title>
        Code Vulnerability List
        <v-spacer />
        <v-switch
          v-model="hideNonMain"
          dense
          hide-details
          label="Hide non main / main/staging"
        />
        <v-switch
          v-model="hideMediumLow"
          dense
          hide-details
          label="Hide medium & low"
        />
      </v-card-title>
      <v-card-text v-if="tableData">
        <p>
          Please ensure you check an resolve all Critical & High vulnerabilities as soon as possible. To view the vulnerabilities click on the Image URL and go to the Vulnerabilities tab. If you see a package listed that is not in the package.json file run `npm ls {package name}` and it will tell you which package installs that dependency.
        </p>
        <f-table :items="tableItems" :columns="columns">
          <template v-slot:fullName="{ item }">
            <span v-if="item.fullName.substring(0, 17) === 'FundamentalMedia/'">{{ item.fullName.substring(17) }}</span>
            <span v-else>{{ item.fullName }}</span>
          </template>
          <template v-slot:branch="{ item }">
            <a :href="`https://github.com/${item.fullName}/tree/${item.branch}`" target="_blank">{{ item.branch }}</a>
          </template>
          <template v-slot:gcpImageUrl="{ item }">
            <a :href="`https://${item.gcpImageUrl}`" target="_blank">{{ item.gcpImageUrl }}</a>
          </template>
        </f-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CodeVulnerability from '../../../../model/code/Vulnerability';

const codeVulnerability = new CodeVulnerability();

export default {
  name: 'CodeVulnerabilityList',
  data() {
    return {
      columns: [
        {
          heading: 'Repo Name',
          filter: "select",
          value: 'fullName',
        },
        {
          heading: 'Branch',
          filter: "select",
          value: 'branch',
        },
        {
          heading: 'Image URL',
          value: 'gcpImageUrl',
        },
        {
          heading: 'Critical',
          value: 'vulnCritical',
        },
        {
          heading: 'High',
          value: 'vulnHigh',
        },
        {
          heading: 'Medium',
          value: 'vulnMedium',
        },
        {
          heading: 'Low',
          value: 'vulnLow',
        },
      ],
      hideNonMain: true,
      hideMediumLow: true,
      tableData: null,
    };
  },
  created() {
    codeVulnerability.getRepoVulnerability().then((res) => {
      this.tableData = res;
    })
  },
  computed: {
    tableItems() {
      if (!this.tableData) {
        return [];
      }
      return this.tableData.filter(item => {
        if (this.hideNonMain && !['master', 'main', 'main/staging'].includes(item.branch)) {
          return false;
        }
        if (this.hideMediumLow && !item.vulnCritical && !item.vulnHigh) {
          return false;
        }
        return !!item.vulnCritical || !!item.vulnHigh || !!item.vulnMedium || !!item.vulnLow;
      })
    }
  }
};
</script>
