<template>
  <div class="auth">
    <v-progress-circular
      v-if="loading"
      :size="200"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
    <div v-else>
      {{errorDescription ? errorDescription : 'Github Token Created'}}
    </div>
  </div>
</template>

<script>
  import code from "@/model/code/Code";

  export default {
    name: 'CodeAuth',
    data() {
      return {
        loading: null,
        errorDescription : null
      };
    },
    async created() {
      this.loading = true

      if (this.$route.query && this.$route.query.code) {
        const returnCode = this.$route.query.code;
        try {
          const token = await code.accessToken(returnCode)
          if(token && token.error){
            this.errorDescription = token.error_description || "Error whilst setting token"
          }else{
            this.$store.commit('SET_TOKEN', token && token.access_token)
            const urlRedirection = localStorage.getItem("current_url_gh_call")
            setTimeout( () => window.location.href = urlRedirection, 3000);
          }
          this.loading = false
        } catch (error) {
          console.error(error)
          this.errorDescription = error
        }
      }
    }
  };
</script>

<style scoped>
.auth{
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
}
div[role=progressbar]{
  margin: 0 auto;
}
</style>
